<template>
  <v-container fluid>
    <page-header title="員工管理">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreateAdmin' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="AdminDetail"
      @options-update="getList()"
    >
      <template #item.admin_type="{ item }">{{ item.admin_type | formatAdminType }} </template>
    </datatable>
  </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'AdminList',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '員工ID', value: 'id', width: 100 },
      { text: '員工名稱', value: 'name', width: 200 },
      { text: '權限', value: 'admin_type' },
      { text: '', value: 'actionView', align: 'end', width: 150 },
    ],
  }),
  methods: {
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l: this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10 ? this.tablePageLimit : undefined,
        }
        
        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }
      
      this.tableLoading = true

      try {
        let payload = {
          limit: this.tablePageLimit,
          page: this.tablePage - 1,
        }

        const user = this.getCurrentUserData()
        const { data, total } = await this.$Fetcher.GetAdmins(payload, user.id, user.token)
        this.tableItemTotal = total
        this.tableData = data.map(el => {
          return {
            id: el.id,
            name: el.username,
            admin_type: el.permission_type,
          }
        })
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.$store.dispatch('toggleSnack', {
            type: 'error',
            message: err,
          })
        }
        this.tableData = []
      } finally {
        this.tableLoading = false
      }
    },
  },

  mounted() {
    this.getQueries()
  },
}
</script>
